<template>
  <div>
      <AdminNavBar/>
      <div class="row mt-4 mb-4">
          <div class="col-md-2 offset-md-1">
              <router-link to="/admin-dashboard/partners">
                     <button class="btn purple-btn">Back</button>
              </router-link>

          </div>
          <div class="col-md-3 offset-md-1">
             <h3 class="text-center purple-title">{{name}} Users </h3>
          </div>
      </div>
      <div class="row mt-4">
          <div class="col-md-7 offset-md-2">
          <AdminPartnerUsersTable :itemsProp="users"/>

          </div>
      </div>
      </div>
</template>

<script>
import {adminService} from '../../_services/admin.service'
import AdminPartnerUsersTable from '../../components/Admin/AdminPartnerUsersTable'
import AdminNavBar from '../../components/Admin/AdminNavBar.vue'
export default {
    props:{
        orgName: String
    },
    components:{
        AdminPartnerUsersTable,
        AdminNavBar
    },
    data(){
        return{
            name: "",
            users: []
        }
    },
    mounted(){
        this.name = localStorage.getItem('OrganizationName')
        adminService.getAllUsersByPartnerId(this.$route.params.id)
        .then((response) => {
            this.users = response.data;
        })
    }
}
</script>

<style>

</style>
